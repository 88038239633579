import logo from './logo.svg';
import './App.css';
import Header from './components/Header'
import Main from './components/Main'

function App() {
  return (
    <>
    <Header/>
    <Main/>
    </>
  );
}

export default App;
