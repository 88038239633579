import React from 'react'
import './Main.css'
import JetCorrect from "../images/jetcorrect.png"
import Ethersearch from "../images/ethersearch.png"
import Nhl from "../images/nhl.png"
import Blockchain from "../images/blockchain.png"
import Project from "./Project"

function Main () {
    return(
        <>
        <div className="main-container">
            <div className="title-container">

                <div className="skills-container">
                    <h1 className="title-skills">skills + courses</h1>
                    <h2 className="skill-first"> python</h2>
                    <h2 className="skill"> c++</h2>
                    <h2 className="skill"> javascript</h2>
                    <h2 className="skill"> react</h2>
                    <h2 className="skill"> html</h2>
                    <h2 className="skill"> css</h2>
                    <h2 className="skill"> node.js</h2>
                    <h2 className="skill"> express</h2>
                    <h2 className="skill"> postgresql</h2>
                    <h2 className="skill"> git</h2>
                    <h2 className="skill"> aws</h2>
                    <h2 className="skill"> data structures</h2>
                    <h2 className="skill"> algorithms</h2>
                    <h2 className="skill"> cybersecurity</h2>
                    <h2 className="skill"> ai</h2>
                </div>
                <h1 className="title-main">experience + projects</h1>
            </div>
            <div className="multi-container">
                <Project 
                    projectTitle="jetcorrect"
                    projectTech="react - node.js - express - postgresql - aws amplify |
                    lambda | rds"
                    projectDesc="modern take on small-scale client storage"
                    projectImg={JetCorrect}
                    projectLinks="https://github.com/niksuyko/jet-soft-public"

                />
                <Project 
                    projectTitle="ethersearch"
                    projectTech="react - node.js - express - alchemy api - etherscan api"
                    projectDesc="blockchain scanner for fast-paced research"
                    projectImg={Ethersearch}
                    projectLinks="https://github.com/niksuyko/ethersearch"

                />
                <Project 
                    projectTitle="nhl season stats"
                    projectTech="react - node.js - express - nhl api"
                    projectDesc="tracker for 2023-2024 nhl season stats"
                    projectImg={Nhl}
                    projectLinks="https://github.com/niksuyko/nhl-app"

                />

                <Project 
                    projectTitle="blockchain community"
                    projectTech="solidity - bscscan - etherscan - community management"
                    projectDesc="200+ member community for small-scale trading"
                    projectImg={Blockchain}
                    projectLinks="https://github.com/niksuyko/solidity-finance"

                />

            </div>   
        </div>

        
        </>
    )
}

export default Main

//projectTitle
//projectTech
//projectDesc
//projectImg
//projectLinks