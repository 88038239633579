import React from 'react'
import './Project.css'
function Project ({projectTitle, projectTech, projectDesc, projectImg, projectLinks}) {
    return(
    <div className="project-container">
        <span className="title-container">
            <h1 className="project-title"><a className = "link" href={projectLinks}>{projectTitle}</a></h1>
            <div className="tech-div">
                <h2 className="tech">{projectTech}</h2>
            </div>
        </span>
            <h2 className="project-description">{projectDesc}</h2>         
            <img className="project-img" src={projectImg}/>
    </div>
    )
}

export default Project

//projectTitle
//projectTech
//projectDesc
//projectImg
//projectLinks