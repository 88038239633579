import React from 'react'
import './Header.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GithubIcon from '@mui/icons-material/GitHub';

function Header () {
    return(
    <div className="header">
        <h1 className="title">nik suyko's portfolio</h1>
        <div className="icons">
            <a className="icon-link" href="https://www.linkedin.com/in/nik-suyko">
                <LinkedInIcon className="linkedin"/>
            </a>
            <a className="icon-link" href="https://www.github.com/niksuyko">
                <GithubIcon className="github"/>
            </a>
        </div>
    </div>
    )
}

export default Header